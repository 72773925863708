/*
====================
=======COLORS=======
====================
*/
$black: #111111;
$darkgray: #222222;
$lightgray: #e9e5ef;
$white: #ffffff;
$purple: #8569db;
$darkblue: #1a204c;
$red: #f74569;
$green: #92cf51;

/*
===================
======SPACING======
===================
*/
$tiny_spacing: 4px;
$small_spacing: 8px;
$medium_spacing: 16px;
$large_spacing: 24px;
$xlarge_spacing: 48px;


/*
====================
=====ANIMATIONS=====
====================
*/
$short_duration: 0.2s;
$medium_duration: 0.4s;
$long_duration: 1s;

/*
==================
=======MISC=======
==================
*/
$high_z_index: 9999;
$header_height: 80px;
$footer_height: 72px;
$standard_box_shadow: 6px 6px 9px transparentize($black, 0.8);
$standard_border_radius: 12px;

/*
====================
=====RESPONSIVE=====
====================
*/
$directions: (
    '<': max-width,
    '<=': max-width,
    '>': min-width,
    '>=': min-width,
    between: between
);

/*
Example usage:
@include responsive('<', 600px) {...}
@include responsive('>=', 400px) {...}
@include responsive('between', 400px, 600px) {...}
*/
@mixin responsive($direction, $px, $px2: null) {
    $dir: map-get($directions, $direction);
    @if $dir == null {
        @warn 'missing direction "#{$direction}"';
    } @else {
        @if $dir == 'between' and $px2 != null {
            @media only screen and (min-width: $px) and (max-width: $px2) {
                @content;
            }
        } @else {
            @media only screen and ($dir: $px) {
                @content;
            }
        }
    }
}

@mixin cover() {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
