@import './Variables';

.tooltip {
  position: relative;

  .tooltipIcon {
    width: 20px;
    height: 20px;
    font-size: 0.8rem;
    font-weight: bold;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity $short_duration;

    &.info {
      border-radius: 50%;
      border: 2px solid $darkgray;
      color: $darkgray;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      vertical-align: top;
      width: 20px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .tooltipContents {
    z-index: $high_z_index;
    background: $white;
    border-radius: $standard_border_radius;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: $small_spacing;
    padding: 6px 12px;
    box-shadow: 0 1px 4px transparentize($black, 0.7);
    transition: opacity $short_duration;

    &.show {
      opacity: 1;
    }

    &.left {
      left: -256px;
    }

    img {
      width: 100%;
    }
  }
}
