@import './Variables';

.dashboard {
  flex: 1;
  max-height: calc(100% - 77px);
  position: relative;
  overflow-y: auto;

  .spinnerContainer {
    @include cover();
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dashboardContent {
    display: flex;
    align-items: flex-start;

    .welcome {
      border-radius: $standard_border_radius;
      background: $white;
      box-shadow: $standard_box_shadow;
      margin: $xlarge_spacing;
      padding: $xlarge_spacing;

      .welcomeSubtitle {
        font-weight: bold;
        opacity: 0.6;
      }
    }

    .rightColumn {
      width: 396px;

      .events {
        border-radius: $standard_border_radius;
        background: $white;
        box-shadow: $standard_box_shadow;
        margin: $xlarge_spacing $xlarge_spacing $xlarge_spacing 0;
        padding-bottom: $large_spacing;

        h1 {
          padding: $xlarge_spacing;
          padding-bottom: $large_spacing;
        }

        .noEvents {
          opacity: 0.6;
          margin-top: $large_spacing;
          padding: 0 $xlarge_spacing;
        }
      }

      .status {
        border-radius: $standard_border_radius;
        background: $white;
        box-shadow: $standard_box_shadow;
        margin: $xlarge_spacing $xlarge_spacing $xlarge_spacing 0;
        padding: $xlarge_spacing;

        h1 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .cameras {
          margin-top: $large_spacing;

          .camera {
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            margin: $small_spacing 0;

            .cameraStatus {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              margin-right: $small_spacing;
              flex-shrink: 0;

              &.online { background: $green; }
              &.offline { background: $red; }
            }

            .cameraName {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .data {
      flex: 1;

      .statistics {
        display: flex;

        .statistic {
          flex: 1;
          border-radius: $standard_border_radius;
          background: $white;
          box-shadow: $standard_box_shadow;
          padding: $xlarge_spacing;
          margin: $xlarge_spacing $xlarge_spacing / 2;
          margin-top: 0;

          &:first-child { margin-left: $xlarge_spacing; }
          &:last-child { margin-right: $xlarge_spacing; }

          .statisticSubtitle {
            font-weight: bold;
            opacity: 0.6;
          }

          .statisticDataContainer {
            .statisticData {
              padding: $xlarge_spacing 0;
              border-top: 1px solid lighten($purple, 30%);

              .statisticDataValue {
                font-size: 1.4rem;
                font-weight: bold;
              }

              .statisticDataLabel {
                margin-top: 2px;
              }

              &:first-child { border-top: none; }
              &:last-child { padding-bottom: $medium_spacing; }
            }
          }
        }
      }

      .averageGraphs {
        .averageGraph {
          border-radius: $standard_border_radius;
          background: $white;
          box-shadow: $standard_box_shadow;
          padding: $xlarge_spacing;
          margin: 0 $xlarge_spacing $xlarge_spacing $xlarge_spacing;

          .averageGraphTop {
            display: flex;
            align-items: center;
            height: 26px;
            margin-bottom: $large_spacing;

            h1 {
              margin-right: $small_spacing;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .barGraph {
            display: flex;
            align-items: flex-end;

            .barContainer {
              flex: 1;

              .bar {
                background: $purple;
                margin: 2px;
                min-height: 4px;
              }

              .barLabel {
                font-size: 0.6rem;
                line-height: 1.4em;
                opacity: 0.6;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
