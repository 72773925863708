@import './Variables';

.event {
  padding: $medium_spacing $xlarge_spacing;
  border-top: 1px solid lighten($purple, 30%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .timestamp {
    line-height: 1em;
    opacity: 0.6;
  }
}