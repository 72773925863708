@import './Variables';

.island {
  border-radius: $standard_border_radius;
  background: $white;
  box-shadow: $standard_box_shadow;
  padding: $xlarge_spacing;
  margin: $xlarge_spacing;

  &.dark {
    background: $darkblue;
    color: $white;
  }
}
