@import './Variables';
$togglewidth: 52px;
$toggleheight: 24px;
$togglepadding: 2px;
$switchwidth: $toggleheight - $togglepadding * 2 - 1;

.toggle {
  .toggleLabel {
    padding: $small_spacing 0;
    margin-bottom: $small_spacing;
  }

  .toggleContainer {
    user-select: none;
    width: $togglewidth;
    height: $toggleheight;
    background: $lightgray;
    border-radius: 20px;
    border: 1px solid $white;
    position: relative;
    transition: background $short_duration;
    cursor: pointer;

    .switch {
      position: absolute;
      top: $togglepadding;
      left: $togglepadding;
      bottom: $togglepadding;
      width: $switchwidth;
      border-radius: 50%;
      background: $white;
      transition: left $short_duration;
    }

    &.on {
      background: $purple;

      .switch {
        left: $togglewidth - $switchwidth - $togglepadding * 2; // use left for animation
      }
    }
  }
}
