@import './Variables';
@import './Button';

.fileUpload {
    display: flex;
    align-items: center;

    .fileInput {
        width: 0.1px; // width/height 0 makes the element not render in some browsers
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .uploadButton {
        @extend .button;
        @extend .small;
        height: 48px;
        border-radius: 24px;
        flex-shrink: 0;
    }

    .uploadText {
        margin-left: $small_spacing;
        flex: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
