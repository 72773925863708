@import './Variables';

.configureFrame {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  user-select: none;
  background: transparentize($black, 0.2);

  .image {
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $large_spacing 300px $large_spacing $large_spacing;
    border-radius: $standard_border_radius;
    overflow: hidden;
  }

  .imageContainer {
    position: relative;
    height: 0;
    width: 100%;

    img,
    .vaModules {
      @include cover();
    }
  }

  .tools {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    max-height: 100%;
    padding: $large_spacing;
    display: flex;
    flex-direction: column;

    .frameIsland {
      background: $white;
      border-radius: $standard_border_radius;
      padding: $large_spacing;

      &:not(:first-child) {
        margin-top: $large_spacing;
      }
    }

    .doneIsland {
      display: flex;
      justify-content: center;
      flex-shrink: 0;

      .button {
        width: 92px;
      }

      .button:first-child {
        background: $darkgray;
        margin-right: $medium_spacing;
      }
    }

    .vaModuleListIsland {
      min-height: 0;
      overflow-y: auto;

      .empty {
        opacity: 0.5;
      }

      .vaModuleListItem {
        &:not(:last-child) {
          padding-bottom: $large_spacing;
          margin-bottom: $large_spacing;
          border-bottom: 1px solid $lightgray;
        }

        .vaModuleListItemTop {
          display: flex;
          align-items: center;
          margin-bottom: $small_spacing;

          .vaModuleName {
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:focus {
              text-overflow: clip;
            }
          }

          .delete {
            font-weight: bold;
            color: $red;
            font-size: 1.4rem;
            line-height: 1.4rem;
            cursor: pointer;
            margin-left: $small_spacing;
            transition: color $short_duration;

            &:hover {
              color: lighten($red, 10%);
            }
          }
        }

        .vaModuleType {
          display: flex;
          align-items: center;

          .tooltip {
            margin-left: $small_spacing;
          }
        }

        .vaModuleActions {
          display: flex;
          align-items: center;
          margin-top: $medium_spacing;

          .inputContainer {
            position: relative;

            .presenceTiming {
              margin-right: 28px + $tiny_spacing;
              max-width: 80px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:focus {
                text-overflow: clip;
              }
            }

            .suffix {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 28px;
              opacity: 0.6;
              font-size: 0.6rem;
            }
          }

          img {
            cursor: pointer;
            width: 18px;
            height: 18px;
            margin-right: $small_spacing;

            &.toggleHidden, &.clone {
              opacity: 0.6 !important;
              transition: opacity $short_duration !important;

              &:hover {
                opacity: 0.8 !important;
              }
            }

            &.reverseCrossing:hover {
              transform: scale(-1, -1);
            }
          }
        }
      }
    }
  }
}
