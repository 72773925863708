@import './Variables';

.configurePage {
  .loading {
    @include cover();
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .configureContents {
    height: 100%;
    display: flex;

    .details {
      flex: 1;
      padding-right: $xlarge_spacing;
      border-right: 1px solid $lightgray;

      .detailsTop {
        display: flex;
        align-items: center;

        a {
          margin-right: $medium_spacing;
          font-size: 2rem;
          line-height: 1rem;
        }
      }

      .fileUpload {
        margin: $large_spacing 0;
      }

      select {
        margin-right: $medium_spacing;
      }
    }

    .settings {
      flex: 1;
      margin-left: $xlarge_spacing;
      overflow: hidden;
      overflow-y: scroll;
      max-height: 100%;

      .section {
        margin-top: $large_spacing;

        .sectionTitle {
          font-weight: bold;
        }

        .imageGrid {
          display: grid;
          grid-auto-rows: min-content;
          grid-template-columns: repeat(auto-fit, minmax(140px, 0.33333fr));
          grid-gap: $tiny_spacing;

          .gridItem {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            user-select: none;

            img {
              @include cover();
              object-fit: cover;
              transition: transform $short_duration;
            }

            .name {
              @include cover();
              font-size: 0.8rem;
              line-height: 1.4em;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              color: $white;
              padding: $small_spacing;
              background: transparentize($black, 0.5);
              transition: opacity $short_duration;
            }

            .delete {
              position: absolute;
              top: 0;
              right: 0;
              padding: $tiny_spacing;
              font-size: 2rem;
              color: $white;
              opacity: 0;
              pointer-events: none;
              text-shadow: 0px 0px $medium_spacing $black;
              transition: opacity $short_duration, transform $short_duration;

              &:hover {
                transform: scale(1.1);
              }
            }

            &:hover {
              .name {
                opacity: 0;
              }

              .delete {
                opacity: 1;
                pointer-events: fill;
              }

              img {
                transform: scale(1.05);
              }
            }
          }

          .add {
            height: 0;
            padding-bottom: 100%;
            background: $purple;
            cursor: pointer;
            user-select: none;
            position: relative;
            transition: background $short_duration;

            .plus {
              @include cover();
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 3rem;
              font-weight: bold;
              color: $white;
            }

            &:hover {
              background: lighten($purple, 5%);
            }
          }
        }

        .sectionRow {
          display: flex;
          flex-direction: row;
          align-items: center;

          .delete {
            font-weight: bold;
            color: $red;
            font-size: 1.4rem;
            line-height: 1.4rem;
            margin: 0 $small_spacing;
            cursor: pointer;
            transition: color $short_duration;

            &:hover {
              color: lighten($red, 10%);
            }
          }
        }
      }

      .button {
        margin-top: $large_spacing;
      }
    }
  }

  .creation {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form {
      width: 480px;
      margin: auto;

      h1 {
        text-align: center;
      }

      select {
        margin-top: $small_spacing;
        margin-right: $small_spacing;

        &.fullWidth { width: 100%; }
      }

      .fileUpload {
        margin-top: $large_spacing;
      }

      .toggle {
        margin-bottom: $large_spacing;
      }

      .buttons {
        display: flex;
        justify-content: center;
        margin-top: $large_spacing;

        .button:first-child {
          background: $darkgray;
          margin-right: $small_spacing;
        }
      }
    }
  }

  .noConfig {
    font-weight: bold;
    color: $red;
    text-align: center;
  }

  .configuration {
    text-align: center;

    .lastGenerated {
      color: $green;
    }
  }

  .inferCommands {
    max-width: 800px;
    width: 100%;
    height: 400px;
    font-family: inherit;
    padding: $small_spacing;
    margin: 0;
    border-radius: $standard_border_radius;
    border: 1px solid $lightgray;
    transition: border $short_duration;

    &:focus {
      outline: none;
      border: 1px solid $purple;
    }
  }
}
