@import './Variables';

.header {
  display: flex;
  justify-content: space-between;
  padding: $large_spacing;
  color: $white;
  font-weight: bold;

  .poweredBy {
    display: flex;
    align-items: center;

    a {
      height: 12px;

      img {
        height: 12px;
        vertical-align: top;
        margin-left: $tiny_spacing;
      }
    }
  }

  .userInfo {
    .fakeLink {
      color: $white;
    }
  }
}
