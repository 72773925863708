@import './Variables';

.vaModules {
  svg {
    @include cover();
    cursor: pointer;
  }

  .button {
    position: absolute;
    top: $large_spacing;
    right: $large_spacing;
  }

  circle.handle {
    fill: $white;
  }

  circle.handleTarget {
    cursor: pointer;
    opacity: 0;
  }


  line {
    pointer-events: none;
  }

  .vaModule {
    .polygonContainer {
      &.disabled {
        pointer-events: none;
      }

      polygon {
        cursor: grab;
        opacity: 0.8;
        &:hover { opacity: 1; }
      }

      text {
        font-weight: bold;
        opacity: 0.8;
        user-select: none;
        pointer-events: none;
      }

      &.selected {
        polygon {
          fill: transparentize($white, 0.8);
          stroke: $white;
        }
        text { fill: $white; }
      }

      &.dragging polygon { cursor: grabbing; }
    }

    .zone {
      polygon {
        fill: transparentize($purple, 0.8);
        stroke: $purple;
      }
      text { fill: darken($purple, 40%); }
    }
    .inZone {
      polygon {
        fill: transparentize($green, 0.8);
        stroke: $green;
      }
      text { fill: darken($green, 40%); }
    }
    .outZone {
      polygon {
        fill: transparentize($red, 0.8);
        stroke: $red;
      }
      text { fill: darken($red, 40%); }
    }
  }
}
