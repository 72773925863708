@import './Variables';

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 1em;
  line-height: 1.6em;
  background: $white;
  color: $darkgray;
  font-family: 'Montserrat', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;

  .page {
    flex: 1;
    position: relative;
    background: $lightgray;

    .colorStripe {
      position: absolute;
      background: linear-gradient(to bottom right, #8358ca, #7a70df);
      top: 0;
      left: 0;
      right: 0;
      height: 280px;
    }

    .pageContent {
      @include cover();
      display: flex;
      flex-direction: column;
    }
  }

  div {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  img {
    transition: opacity $long_duration;
  }

  a, a:link, a:visited, a:active, .fakeLink {
    color: $purple;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    transition: color $short_duration;

    &:hover {
      color: lighten($purple, 5%);
    }
  }

  h1 {
    font-size: 1.8rem;
    margin: 0;
  }

  select {
    font-family: inherit;
    appearance: none;
    padding: $small_spacing;
    padding-right: $medium_spacing + 14px;
    margin: 0;
    height: 48px;
    border-radius: $standard_border_radius;
    border: 1px solid $lightgray;
    background: lighten($lightgray, 12%) url('../images/dropdown.svg') right $small_spacing center/14px 14px no-repeat;
    // hack to hide "focused" outline in firefox
    color: transparent;
    text-shadow: 0 0 0 $darkgray;
    max-width: 100%;

    option {
      // usually you can't style "option" tags but for some reason in ubuntu you can, so this will ensure text is readable
      color: $white;
      background: $black;
    }

    &::-ms-expand {
      display: none;
    }

    &::-ms-value {
      background: transparent;
      color: $black;
    }

    &:focus {
      outline: none;
    }
  }
}
