@import './Variables';

.sidebar {
  width: 320px;
  background: $white;
  box-shadow: $standard_box_shadow;
  transition: margin $short_duration;

  .logoContainer {
    padding: $xlarge_spacing;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .loading {
    padding: $medium_spacing $xlarge_spacing;
  }

  a {
    display: block;
    padding: $medium_spacing $xlarge_spacing;
    color: $darkgray !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &.active {
      background: lighten($purple, 30%);
    }
  }

  &.collapsed {
    margin-left: -320px;
  }
}
