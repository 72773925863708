@import './Variables';

.configure {
  .loading {
    @include cover();
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .createSite {
    height: 100%;
    display: flex;
    flex-direction: column;

    .createSiteForm {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 26px;

      .form {
        width: 480px;

        .fileUpload {
          margin-top: $large_spacing;
        }
      }

      .createButton {
        margin-top: $large_spacing;
      }
    }
  }

  .sitesList {
    .site {
      display: block;
      margin-top: $large_spacing;
    }
  }
}
