@import './Variables';

.islandPage {
  flex: 1;
  max-height: calc(100% - 77px);
  display: flex;

  .island {
    flex: 1;
    max-height: 100%;
    min-width: 0;
    transition: max-width $medium_duration, padding $medium_duration, margin $medium_duration;

    &:nth-child(2) {
      margin-left: 0;
      max-width: 348px;
      padding: 0;

      &.hidden {
        max-width: 0;
        padding: 0;
        margin: $xlarge_spacing 0;
        overflow: hidden;
      }
    }
  }
}
