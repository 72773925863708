@import './Variables';

.graph {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .graphTop {
    display: flex;
    align-items: center;
    height: 26px;
    margin-bottom: $large_spacing;

    .titleContainer {
      display: flex;
      flex: 1;
      min-width: 0;
      padding-right: $large_spacing;

      h1 {
        text-align: left;
        margin-right: $small_spacing;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .graphTooltip {
        .graphTooltipDescription {
          padding: $medium_spacing 0;
        }
      }
    }

    .filters {
      display: flex;
      align-items: center;

      .divider {
        font-weight: bold;
        margin: 0 $small_spacing;
        opacity: 0.6;
      }
    }
  }

  .graphContainer {
    flex: 1;
    max-height: calc(100% - 50px);

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .legend {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;

    .legendEntry {
      display: flex;
      align-items: center;
      margin: 0 $medium_spacing;

      .colorIndicator {
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }
    }
  }

  .sources {
    position: absolute;
    bottom: -28px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.8rem;
    opacity: 0.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.events {
  position: relative;
  padding: 0;
  overflow: visible;

  .titleContainer {
    margin: $xlarge_spacing;
    margin-bottom: $large_spacing;
    display: flex;

    h1 {
      margin-right: $small_spacing;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .eventPagination {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    height: 58px;

    .prevPage,
    .nextPage {
      flex: 1;
      text-align: center;
      padding: $medium_spacing;
      background: $purple;
      color: $white;
      font-size: 2rem;
      cursor: pointer;
      user-select: none;
      transition: background $short_duration;

      &.disabled {
        background: lighten($purple, 20%);
        pointer-events: none;
      }

      &:hover {
        background: lighten($purple, 5%);
      }
    }

    .prevPage {
      border-right: 1px solid $white;
      border-bottom-left-radius: $standard_border_radius;
    }
    .nextPage {
      border-left: 1px solid $white;
      border-bottom-right-radius: $standard_border_radius;
    }
  }
}
