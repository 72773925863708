@import './Variables';

.login {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;

  .island {
    flex: 1;
  }

  .loginForm {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: opacity $short_duration;

    &.loading {
      pointer-events: none;
      opacity: 0.5;
    }

    input {
      height: 48px;
      background: transparentize($white, 0.9);
      border: 2px solid transparentize($white, 0.8);
      border-radius: 24px;
      color: $white;
      font-family: inherit;
      font-size: 0.8rem;
      display: block;
      margin: $large_spacing auto 0 auto;
      padding: $large_spacing;
      width: 100%;
      max-width: 320px;
      transition: border $short_duration;

      &:focus {
        outline: none;
        border-color: $purple;
      }
    }

    .button {
      max-width: 320px;
      margin: 0 auto;
      width: 100%;
      margin-top: $large_spacing;
    }
  }

  .error {
    color: $red;
    font-weight: bold;
  }
}
