@import './Variables';

.react-datepicker-wrapper {
  input[type=text] {
    font-family: inherit;
    font-size: 0.8rem;
    width: 152px;
    height: 40px;
    padding: 10px 12px 10px 36px;
    border: 2px solid $purple;
    border-radius: $standard_border_radius;
    color: $purple;
    font-weight: bold;
    background: $white url('../images/calendar.svg') left 12px center/14px 14px no-repeat;
    transition: width $short_duration;

    &.small {
      width: 132px;
    }

    &:focus {
      outline: none;
      border-color: $purple;
    }
  }
}
