@import './Variables';

.heatMap {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .heatMapTop {
    display: flex;
    align-items: center;
    height: 26px;
    margin-bottom: $large_spacing;

    .titleContainer {
      display: flex;
      flex: 1;
      min-width: 0;
      padding-right: $large_spacing;

      h1 {
        text-align: left;
        margin-right: $small_spacing;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .filters {
      display: flex;
      align-items: center;

      .arrow {
        height: 44px;
        font-size: 2rem;
        font-weight: bold;
        color: $purple;
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity $short_duration;

        &:hover {
          opacity: 1;
        }

        &.prevInterval { margin-right: $medium_spacing; }
        &.nextInterval { margin-left: $medium_spacing; }
      }

      select {
        font-family: inherit;
        appearance: none;
        font-size: 0.8rem;
        padding: 10px 24px 10px 12px;
        border: 2px solid $purple;
        border-radius: $standard_border_radius;
        background: $purple url('../images/caret.svg') right $small_spacing center/14px 14px no-repeat;
        color: $white;
        font-weight: bold;
        margin-left: $medium_spacing;
        height: 40px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .heatMapContainer {
    flex: 1;
    min-height: 0;
    margin-right: 60px;

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .imageContainer {
      position: relative;
      margin: auto;

      img {
        @include cover();
      }

      .heatMapData {
        @include cover();
        display: flex;
        flex-direction: column;

        .heatMapDataRow {
          max-width: 100%;
          flex: 1;
          display: flex;

          .heatMapDataCell {
            flex: 1;
            position: relative;

            .heatMapDataCellColor {
              @include cover();
              mix-blend-mode: multiply;
            }

            .heatMapDataCellTooltip {
              z-index: $high_z_index;
              background: $white;
              pointer-events: none;
              opacity: 0;
              position: absolute;
              left: 100%;
              top: 0;
              margin-left: $small_spacing;
              padding: 6px 12px;
              box-shadow: 0 1px 4px transparentize($black, 0.7);
              display: flex;
              align-items: center;

              .color {
                width: 12px;
                height: 12px;
                margin-right: 16px;
              }

              .value {
                font-weight: bold;
              }
            }

            &:hover {
              .heatMapDataCellTooltip {
                opacity: 1;
              }
            }
          }
        }
      }

      .legend {
        position: absolute;
        right: -92px;
        top: calc(50% - 100px);
        display: flex;

        .label {
          width: 25px;
          writing-mode: tb-rl;
          transform: rotate(180deg);
          text-align: center;
          font-size: 0.8rem;
          opacity: 0.6;
        }

        .colorsIndicator {
          width: 12px;
          height: 200px;
        }

        .valuesIndicator {
          height: 200px;
          min-width: 36px;
          background: $white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0 $small_spacing;
          font-weight: bold;
          font-size: 0.8rem;
        }
      }
    }
  }

  .sources {
    position: absolute;
    bottom: -28px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.8rem;
    opacity: 0.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
