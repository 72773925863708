@import './Variables';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparentize($white, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modalTitle {
    margin-bottom: $large_spacing;
  }

  .button {
    margin-top: $large_spacing;
  }
}
