@import './Variables';

.spinner {
  position: relative;
  width: 180px;
  height: 180px;
  transform: rotate(45deg);

  .box {
    width: 72px;
    height: 72px;
    position: absolute;
    border: 12px solid $purple;

    @keyframes boxslide1 {
      0%, 8.33%, 16.66%, 100% { transform: translate(0%, 0%); }
      24.99%, 33.32%, 41.65% { transform: translate(100%, 0%); }
      49.98%, 58.31%, 66.64% { transform: translate(100%, 100%); }
      74.97%, 83.30%, 91.63% { transform: translate(0%, 100%); }
    }

    &:nth-child(1) {
      top: 0;
      left: 0;
      animation: boxslide1 5000ms linear infinite;
    }

    @keyframes boxslide2 {
			0%, 8.33%, 91.63%, 100% { transform: translate(0%, 0%); }
			16.66%, 24.99%, 33.32% { transform: translate(0%, 100%); }
			41.65%, 49.98%, 58.31% { transform: translate(-100%, 100%); }
			66.64%, 74.97%, 83.30% { transform: translate(-100%, 0%); }
		}

    &:nth-child(2) {
      top: 0;
      left: 72px;
      opacity: 0.6;
      animation: boxslide2 5000ms linear infinite;
    }

    @keyframes boxslide3 {
			0%, 83.30%, 91.63%, 100% { transform: translate(0, 0); }
			8.33%, 16.66%, 24.99% { transform: translate(-100%, 0); }
			33.32%, 41.65%, 49.98% { transform: translate(-100%, -100%); }
			58.31%, 66.64%, 74.97% { transform: translate(0, -100%); }
		}

    &:nth-child(3) {
      top: 72px;
      left: 72px;
      opacity: 0.2;
      animation: boxslide3 5000ms linear infinite;
    }
  }
}
